// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { VERSION } from './version';

export const environment = {
  production: true,
  apiUrl: 'https://api.cbre.com:443/t/advisory/cdmapi-prod/1.0.0.8',
  tenant: "cbreconnect.onmicrosoft.com",
  instance: 'https://cbreconnect.b2clogin.com/',
  clientId: "55f1c10c-cc48-4c45-abe5-88022d8e66aa",   //Prod
  authnPolicy: "b2c_1a_giaidp_signup_signin2",
  wso2TokenURL: "https://api.cbre.com:443/token", //Prod
  wso2ClientId: "HFramMPvWAAd2F7x2CddXYc3fnga", //Prod
  wso2ClientSecret: "qJ_GPd2fnhUSGtH7cnApqm33OPYa", //Prod
  OktaDomain: "https://login.cbre.com/oauth2/ausktp2owgxafQHNU4x7",
  OktaClientId: "0oaktovyug7j9nEiG4x7",
  OktaRedirectUri: 'https://cdm.cbre.com',
  Oktascopes: ['openid', 'profile', 'email'],
  IsOktaAuthentication: "true",
  appVersion: VERSION.version,
  minutesUntilAutoLogout: 25
};

/*
  * In development mode, to ignore zone related error stack frames such as
  * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
  * import the following file, but please comment it out in production mode
  * because it will have performance impact when throw error
  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
